.team-section {
    // margin-bottom: 60px;

    .team-title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    .founders {
        padding-top: 60px;
    }

    @include media-breakpoint-up(lg) {
        // margin-bottom: 120px;

        .founders {
            padding-top: 80px;
        }
    }
}

// Team ITEM

.founder {
    border-radius: 3px;
    background-color: $white;
    text-align: center;
    position: relative;
    padding-top: 155px;
    margin-bottom: 100px;
    padding-bottom: 50px;

    .founder-pic {
        @include center-content(horizontal);
        top: 0;
        margin-top: -80px;
    }

    .founder-description {
        padding: 0 50px;
    }

    .founder-description-top {
        padding-bottom: 34px;
    }

    .founder-description-bottom {
        border-top: 1px solid $green;
        padding-top: 22px;
    }

    .founder-role {
        display: block;
    }

    .founder-sub {
        margin-bottom: 34px;
    }

    .founder-teams-sub {
        margin-bottom: 10px;
    }

    .founder-former-role {
        display: block;
        margin-bottom: 10px;
    }

    .founders-teams {
        display: flex;
        justify-content: space-evenly;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        height: 500px;

        .founder-description {
            padding: 0 30px;
        }
    }
}
