.hero {
  text-align: center;
  display: flex;
  flex-direction: column;
  //height: $hero-height;
  //max-height: $hero-height;
  color: $white;
  background-color: #001e37;

  .homepage-hero {
    width: 100%;
  }

  .hero-video-wrapper {
    position: absolute;
    z-index: auto;

    > .hero-video {
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
  }

  .logo {
    display: block;
    margin-top: 20px;
    position: relative;
    margin-top: -11.5vw;
    margin-bottom: 7vw;

    > img {
      max-width: 50px;
      width: 50vw;
    }
  }

  .top-section {
    position: relative;
    height: 100%;
    //background-image: url($images-path + '/hero-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .announcement-banner {
    display: flex;
    padding: 18px 0px;
    background-color: #3476ff;
    overflow-wrap: normal;
    text-align: left;

    span {
      display: inline-block;
      max-width: 1035px;
      margin: 0px auto;
    }

    .md {
      display: inline;
    }
  }

  .bottom-section {
    padding-top: 2vw;
  }

  .hero-title {
    position: relative;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }

  .official-partners {
    background-color: $almost-black;
  }

  .partners-heading {
    @include center-content(horizontal);
    bottom: 0;
    display: flex;
    align-items: center;

    &::before,
    &::after {
      content: '';
      width: 50px;
      height: 1px;
      background-color: $white;
    }

    &:before {
      margin-right: 20px;
    }

    &:after {
      margin-left: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    .official-partners {
      height: 23vw;

      .partners-banner {
        object-fit: cover;
        height: 100%;
      }
    }

    .announcement-banner {
      span.lg {
        display: inline-block;
        font-size: small;
        padding: 0px 10px;
        .md {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .logo {
      z-index: 3;
      > img {
        max-width: 390px;
        width: 50vh;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .logo {
      // margin-top: 33.5vw;
    }
  }

  // Special sizing for phone landscape orientation
  @media only screen and (min-device-width: 540px) and (max-device-width: 1024px) and (orientation: landscape) {
    .logo {
      > img {
        margin-top: -50px;
        max-width: 125px;
      }
    }

    .hero-video-wrapper {
      top: -80px;
    }
  }

  @media only screen and (min-device-width: 540px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .logo {
      > img {
        margin-top: -50px;
        max-width: 125px;
      }
    }

    .hero-video-wrapper {
      top: -80px;
    }
  }
}
