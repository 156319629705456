.footer {
    background-color: $deep-blue;

    .footer-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .footer-link-list {

        .footer-list-title {
            margin-bottom: 50px;
        }

        .footer-list-item {
            display: block;
            margin: 15px 0;
        }
    }

    .social {
        display: flex;
    }

    .social-list {
        margin-top: 50px;

        .social-item {
            margin-right: 15px;
            @include media-breakpoint-down(sm) {
                margin-right: 2px;
            }
            @include media-breakpoint-down(md) {
                margin-right: 2px;
            }
        }
    }

    .copyright {
        margin: 0;
    }

    .footer-logo-column {
        order: -1;
    }

    .footer-logo {
        text-align: center;
        margin-top: -75px;

        .logo {
            img {
                max-width: 75%;
            }
        }
    }


    @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 100px;

        .footer-logo-column {
            order: inherit;
        }

        .footer-logo {
            margin-top: -150px;
        }

        .footer-col {
            margin-bottom: 0;

            &:first-child {
                margin-bottom: 0;
            }
        }

        .social-list {

            .social-item {
                margin-right: 10px;
            }
        }

        .copyright {
            margin: 0;
            white-space: nowrap;
        }
    }
}
