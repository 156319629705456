.links-section {
    position: relative;
    padding-top: 0px;
    padding-bottom: 100px;
    background-image: url($images-path + '/community-links-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    h2 {
        padding-right: 50px;
        margin-bottom: 35px;
        @include media-breakpoint-down(md) {
            padding-right: 0px;
        }
    }

    .section-text{
        padding-right: 100px;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            padding-right: 0px;
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: 80px;
    }


    .social-icons-wrapper{
        img {
            max-width: 14%;
            margin-right: 6%;
        }
    }

    .conatiner,
    .row {
        height: 100%;
    }

    .section-image {
        transform: translate(8.5vw, 140px) scale(1.4);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        padding-top: 50px;
        .row {
            flex-direction: row-reverse;
        }

        .section-image {
            transform: translate(-2.4vw, 110px) scale(1.3);
        }

        .img-fluid {
            max-width: 100%;
        }
    }

    @include media-breakpoint-up(xl) {
        .section-image {
            transform: translate(-2.4vw, 140px) scale(1.3);
        }
    }
}
