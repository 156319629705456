$fonts-path: '../assets/fonts';
$font-avenir-bold: 'AvenirNextLTW01-Bold';
$font-avenir-heavy: 'AvenirNextLTW01-Heavy';
$font-avenir-regular: 'AvenirNextLTW01-Regular';
$font-druktext-medium-italic: 'DrukText-MediumItalic';
$font-druk-super-italic: 'Druk-SuperItalic';
$font-bison: 'Bison';
$font-integral: 'Integral';

// Avenir ------------
@font-face {
    font-family: $font-avenir-regular;
    src: url('#{$fonts-path}/regular/avenir-next-regular.eot?#iefix');
    src: url('#{$fonts-path}/regular/avenir-next-regular.eot?#iefix') format('eot'),
    url('#{$fonts-path}/regular/avenir-next-regular.woff2') format('woff2'),
    url('#{$fonts-path}/regular/avenir-next-regular.woff') format('woff'),
    url('#{$fonts-path}/regular/avenir-next-regular.ttf') format('truetype');
}

@font-face {
    font-family: $font-avenir-bold;
    src: url('#{$fonts-path}/bold/avenir-next-bold.eot?#iefix');
    src: url('#{$fonts-path}/bold/avenir-next-bold.eot?#iefix') format('eot'),
    url('#{$fonts-path}/bold/avenir-next-bold.woff2') format('woff2'),
    url('#{$fonts-path}/bold/avenir-next-bold.woff') format('woff'),
    url('#{$fonts-path}/bold/avenir-next-bold.ttf') format('truetype');
}

@font-face {
    font-family: $font-avenir-heavy;
    src: url('#{$fonts-path}/strong/avenir-next-strong.eot?#iefix');
    src: url('#{$fonts-path}/strong/avenir-next-strong.eot?#iefix') format('eot'),
    url('#{$fonts-path}/strong/avenir-next-strong.woff2') format('woff2'),
    url('#{$fonts-path}/strong/avenir-next-strong.woff') format('woff'),
    url('#{$fonts-path}/strong/avenir-next-strong.ttf') format('truetype');
}

// Bison ------------
// Bold
@font-face {
    font-family: $font-bison;
    src: url('#{$fonts-path}/bold/Bison-Bold.woff2') format('woff2'),
    url('#{$fonts-path}/bold/Bison-Bold.woff') format('woff'),
    url('#{$fonts-path}/bold/Bison-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

// Bold Italic
@font-face {
    font-family: $font-bison;
    src: url('#{$fonts-path}/bold/Bison-BoldItalic.woff2') format('woff2'),
    url('#{$fonts-path}/bold/Bison-BoldItalic.woff') format('woff'),
    url('#{$fonts-path}/bold/Bison-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
}

// Demibold Italic
@font-face {
    font-family: $font-bison;
    src: url('#{$fonts-path}/bold/Bison-DemiBoldItalic.woff2') format('woff2'),
    url('#{$fonts-path}/bold/Bison-DemiBoldItalic.woff') format('woff'),
    url('#{$fonts-path}/bold/Bison-DemiBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
}

// Demibold
@font-face {
    font-family: $font-bison;
    src: url('#{$fonts-path}/bold/Bison-DemiBold.woff2') format('woff2'),
    url('#{$fonts-path}/bold/Bison-DemiBold.woff') format('woff'),
    url('#{$fonts-path}/bold/Bison-DemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

// Druk ------------
// Medium Italic
@font-face {
    font-family: $font-druktext-medium-italic;
    src: url('#{$fonts-path}/medium/DrukText-MediumItalic.eot?#iefix');
    src: url('#{$fonts-path}/medium/DrukText-MediumItalic.eot?#iefix') format('eot'),
    url('#{$fonts-path}/medium/DrukText-MediumItalic.woff2') format('woff2'),
    url('#{$fonts-path}/medium/DrukText-MediumItalic.woff') format('woff'),
    url('#{$fonts-path}/medium/DrukText-MediumItalic.ttf') format('truetype');
}

// Super Italic
@font-face {
    font-family: $font-druk-super-italic;
    src: url('#{$fonts-path}/super/Druk-SuperItalic.eot?#iefix');
    src: url('#{$fonts-path}/super/Druk-SuperItalic.eot?#iefix') format('eot'),
    url('#{$fonts-path}/super/Druk-SuperItalic.woff2') format('woff2'),
    url('#{$fonts-path}/super/Druk-SuperItalic.woff') format('woff'),
    url('#{$fonts-path}/super/Druk-SuperItalic.ttf') format('truetype');
}

// Integral ------------
// Heavy
@font-face {
    font-family: $font-integral;
    src: url('#{$fonts-path}/strong/IntegralCF-Heavy.eot?#iefix');
    src: url('#{$fonts-path}/strong/IntegralCF-Heavy.eot?#iefix') format('eot'),
    url('#{$fonts-path}/strong/IntegralCF-Heavy.woff2') format('woff2'),
    url('#{$fonts-path}/strong/IntegralCF-Heavy.woff') format('woff'),
    url('#{$fonts-path}/strong/IntegralCF-Heavy.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

// Heavy Oblique (italic)
@font-face {
    font-family: $font-integral;
    src: url('#{$fonts-path}/strong/IntegralCF-HeavyOblique.eot?#iefix');
    src: url('#{$fonts-path}/strong/IntegralCF-HeavyOblique.eot?#iefix') format('eot'),
    url('#{$fonts-path}/strong/IntegralCF-HeavyOblique.woff2') format('woff2'),
    url('#{$fonts-path}/strong/IntegralCF-HeavyOblique.woff') format('woff'),
    url('#{$fonts-path}/strong/IntegralCF-HeavyOblique.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

// Bold
@font-face {
    font-family: $font-integral;
    src: url('#{$fonts-path}/bold/IntegralCF-Bold.eot?#iefix');
    src: url('#{$fonts-path}/bold/IntegralCF-Bold.eot?#iefix') format('eot'),
    url('#{$fonts-path}/bold/IntegralCF-Bold.woff2') format('woff2'),
    url('#{$fonts-path}/bold/IntegralCF-Bold.woff') format('woff'),
    url('#{$fonts-path}/bold/IntegralCF-Bold.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

// Bold Oblique (italic)
@font-face {
    font-family: $font-integral;
    src: url('#{$fonts-path}/bold/IntegralCF-BoldOblique.eot?#iefix');
    src: url('#{$fonts-path}/bold/IntegralCF-BoldOblique.eot?#iefix') format('eot'),
    url('#{$fonts-path}/bold/IntegralCF-BoldOblique.woff2') format('woff2'),
    url('#{$fonts-path}/bold/IntegralCF-BoldOblique.woff') format('woff'),
    url('#{$fonts-path}/bold/IntegralCF-BoldOblique.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

// Regular
@font-face {
    font-family: $font-integral;
    src: url('#{$fonts-path}/regular/IntegralCF-Regular.eot?#iefix');
    src: url('#{$fonts-path}/regular/IntegralCF-Regular.eot?#iefix') format('eot'),
    url('#{$fonts-path}/regular/IntegralCF-Regular.woff2') format('woff2'),
    url('#{$fonts-path}/regular/IntegralCF-Regular.woff') format('woff'),
    url('#{$fonts-path}/regular/IntegralCF-Regular.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

// Regular Oblique (italic)
@font-face {
    font-family: $font-integral;
    src: url('#{$fonts-path}/regular/IntegralCF-RegularOblique.eot?#iefix');
    src: url('#{$fonts-path}/regular/IntegralCF-RegularOblique.eot?#iefix') format('eot'),
    url('#{$fonts-path}/regular/IntegralCF-RegularOblique.woff2') format('woff2'),
    url('#{$fonts-path}/regular/IntegralCF-RegularOblique.woff') format('woff'),
    url('#{$fonts-path}/regular/IntegralCF-RegularOblique.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}
