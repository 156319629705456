.button-link {
    display: block;
    text-align: center;
    padding: 20px 0;
    color: $white;
    background-color: $red;
    border-radius: 44px; // half height of el, will change if font changes
    transition: all .3s ease-in-out;

    &:hover {
        color: $almost-black;
    }
}
