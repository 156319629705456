.talent-section {
    position: relative;
    padding: $section-padding;
    background-image: url($images-path + '/talent-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    .conatiner,
    .row {
        height: 100%;
    }

    .section-image {
        transform: translateY(122px);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;

        .row {
            flex-direction: row-reverse;
        }

        .section-image {
            transform: translateY(198px);
        }
    }

    @include media-breakpoint-up(xl) {
        .section-image {
            transform: translateY(170px);
        }
    }
}
