.headline-section {
    position: relative;
    background-image: url($images-path + '/community-headline-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    h2 {
        @include media-breakpoint-down(xl) {
            font-size: 7.225rem;
        }
        @include media-breakpoint-down(lg) {
            font-size: 6.525rem;
        }
        @include media-breakpoint-down(md) {
            font-size: 6.625rem;
        }
        @include media-breakpoint-down(sm) {
            font-size: 4.625rem;
        }
    }


    .conatiner,
    .row {
        height: 100%;
    }

    @include media-breakpoint-up(lg) {
        padding: 0px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .section-image {
            transform: translate(2.1vw, 147px) scale(1.4);
        }
    }
    @include media-breakpoint-down(md) {
        padding: 80px 0px;
        display: flex;
        align-items: center;
        overflow: hidden;
        .section-image {
            transform: translate(2.1vw, 147px) scale(1.4);
        }
    }
}
