html {
    font-family: Arial, Helvetica, sans-serif;
}

body {
    background: $background-color;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

// Global
.logo {
    > img {
        max-width: 320px;
    }
}

// Cookie Consent
.CookieConsent {
    background-color: $green;
    color: $deep-blue;
    font-size: .8rem;
    font-family: $font-integral;
    font-weight: 400;
    align-items: center !important;

    .cookie-consent-subtext {
        font-family: $font-avenir-regular;
        font-size: .8rem;

        a {
            text-decoration: underline;
        }
    }

    .consent-btn,
    #rcc-decline-button {
        margin: 10px !important;
        padding: 15px !important;
    }

    .consent-btn {
        background-color: $green !important;
        color: $deep-blue !important;
    }

    #rcc-decline-button {
        background-color: $deep-blue !important;
        color: $green !important;
    }
}

