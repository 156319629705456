.downloadcta-buttons{
    img {
        @include media-breakpoint-up(xl) {
            margin: 0.5vw;
            min-width: 200px;
        }
        @include media-breakpoint-down(lg) {
            width: 16vw;
            margin: .5vw;
            max-width: 200px;
        }
        @include media-breakpoint-down(md) {
            width: 13vw;
            margin: 0.5vw;
            min-width: 90px;
        }
        @include media-breakpoint-down(sm) {
            width: 19vw;
            margin: 1vw;
        }
        @include media-breakpoint-down(xs) {
            width: 22vw;
            margin: 1.5vw;
        }
    }
}
.downloadcta-section {
    position: relative;
    padding: $section-padding;
    background-image: url($images-path + '/cta-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    
    @include media-breakpoint-down(md){
        padding: 80px 0;
    }

    .downloadcta-title {
        font-family: $font-druktext-medium-italic;
        text-transform: uppercase;
        font-size: 4rem;
        margin: 1vw;
        @include media-breakpoint-down(md) {
            font-size:2.5rem;
        }
    }
    .logo > img {
        max-width: 290px;
        width: 50vh;
        @include media-breakpoint-down(sm) {
            width: 200px;
            margin: 0.5vw;
        }
    }

    .conatiner,
    .row {
        height: 100%;
        flex-direction: column;
    }

    .section-image {
        transform: translateY(122px);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        padding: 5vw;

        .row {
            flex-direction: column;
        }

        .section-image {
            transform: translateY(198px);
        }
    }

    @include media-breakpoint-up(xl) {
        .section-image {
            transform: translateY(170px);
        }
    }

}