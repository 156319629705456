// Signup Page
.signup-page {
    font-family: $font-avenir-regular;
    background-image: url($images-path + '/signup-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .signup-header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        .logo {
            position: relative;
            z-index: 3;
            padding-right: 10px;
            border-right: 1px solid $white;

            >img {
                max-width: 100%;
                width: 175px;
            }
        }
        .signup-lcs-logo {
            margin-left: 10px;
        }
    }

    .signup-hero {
        text-align: center;
        margin-bottom: 30px;

        .signup-hero-text {
            margin-bottom: 0;
        }

        .signup-hero-text,
        .signup-hero-subtext {
            margin-bottom: 30px;
        }
    }

    .signup-hero-cta {
        display: flex;
        justify-content: space-around;

        a {
            padding: 0 10px;
        }
    }

    .signup-content {
        margin-bottom: 20px;

        img {
            margin-left: -5%;
            max-width: 100%;
        }
    }

    .signup-cta {
        text-align: center;
        margin-bottom: 85px;

        .signup-button {
            width: 400px;
            max-width: 100%;
            margin: 0 auto 35px;
            background-color: $green;
            color: $deep-blue;
            cursor: pointer;

            &:hover {
                background-color: $deep-blue;
                color: $green;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .signup-header {
            .logo {
                padding-right: 50px;
            }
            .signup-lcs-logo {
                margin-left: 50px;
            }
        }
    }

    .form-wrapper {
        text-align: center;
        margin: 50px auto 100px;
        padding: 20px;
        background: rgb(0, 0, 0);
        background: linear-gradient(45deg, rgba(255,255,255, .8), transparent);
        border-radius: 10px;
    }

    iframe {
        .pfy-form-container {
            font-family: $font-avenir-regular;
        }
    }

    @include media-breakpoint-up(lg) {
        .form-wrapper {
            max-width: 60%;
        }

        .signup-hero-subtext {
            max-width: 60%;
            margin: 0 auto 30px;
        }

        .signup-hero-cta {
            justify-content: center;

            a {
                padding: 0 30px;
            }
        }

    }

    @include media-breakpoint-up(xl) {

        .signup-hero-subtext {
            max-width: 55%;
        }

    }
}

// Modal Styles

#prefinery_popup {
    background-image: url($images-path + '/form-bg.jpg');
}

#prefinery_body {
    @include media-breakpoint-up(lg) {
        padding: 0 75px !important;
    }
}

#prefinery_header {

    #prefinery_close {
        opacity: 1 !important;
    }
}

#prefinery_body {
    display: flex;

    iframe {
        max-width: 420px;
        margin: 0 auto;
    }

    @include media-breakpoint-up(md) {
        iframe {
            max-width: auto;
        }
    }
}
