.header {
  background-color: $white;
}

.top-bar {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;

  .desktop-logo {
    display: block;

    .logo-small {
      width: 75px;
      opacity: 1;
      position: absolute;
      top: 5px;
      left: 10px;
      transition: opacity 0.3s ease-in;

      img {
        max-width: 100%;
      }

      .headroom--pinned & {
        opacity: 1;
        width: 75px;
      }
    }
  }

  .mobile-nav-logo {
    display: none;
    img {
      max-width: 100%;
    }
  }

  .hamburger {
    display: none;
  }

  .main-nav {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in;
    flex-grow: 1;

    .headroom--scrolled & {
      .nav-list {
        // transform: translateX(85px);
      }
    }

    .social-icons {
      list-style-type: none;
      display: flex;
      margin-left: auto;

      img {
        margin: auto;
        padding: 20px;
      }
    }

    .nav-list {
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0 0 0 30px;
    }

    .nav-link {
      position: relative;
      text-align: center;
      margin-right: 10px;
      min-width: 92px;
      transition: color 0.2s ease-in;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        left: -0.15rem;
        bottom: 0;
        background-image: url($images-path+'/nav-item-hover.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: -1;
        transition: clip-path 0.2s ease-in;
        clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
      }

      &:hover {
        color: $white;
        &:before {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .desktop-logo {
      display: none;
    }

    .mobile-nav-logo {
      display: block;
    }

    .hamburger {
      display: block;
      z-index: 1000;
      outline: none;
    }

    .main-nav {
      position: fixed;
      padding-top: 70px;
      top: 0;
      height: 100vh;
      width: 150px;
      background-color: $white;
      align-items: center;
      flex-direction: column;
      transform: translateX(-100%);
      z-index: 999;

      .social-icons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        justify-content: flex-start;

        .nav-item {
          padding-right: 55px;
          margin: 0px 10px 0px 0px;
        }
      }

      .mobile-hamburger-logo {
        align-self: flex-end;
        margin-top: auto;
      }

      &.open {
        transform: translateX(0);
      }

      .nav-list {
        flex-direction: column;
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;
        transform: translateX(0) !important;
      }

      .social-icons {
        position: relative;
        margin-top: 0px;
        right: 0;
      }

      .mobile-nav-logo {
        padding: 1rem;
        &::before {
          background-image: none;
        }
      }
    }
  }

  .callout {
    display: flex;
    justify-content: flex-end;

    .callout-link {
      text-decoration: none;
      color: $white;
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      align-items: center;
      padding: 0px 70px 0px 70px;
      position: relative;
      height: $top-bar-height;

      &::before {
        z-index: -1;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: $green;
        clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
      }
    }

    .foam-finger {
      transform: scale(0.5);
      position: absolute;
      bottom: -40px;
      left: -13px;
      // transition: all .3s ease-in;
      @include media-breakpoint-up(md) {
        position: absolute;
        left: 10px;
        bottom: -30px;
        .headroom--unpinned & {
          transform: translateY(-100%);
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .callout {
      .callout-link {
        padding: 0px 125px 0px 150px;
      }

      .foam-finger {
        left: 56px;
        bottom: -40px;
        transform: scale(0.8);
      }
    }

    .desktop-logo {
      .logo-small {
        top: 10px;
        left: 9vw;

        .headroom--pinned & {
          opacity: 1;
          // width: 150px;
        }
      }
    }

    .main-nav {
      .nav-list {
        transform: translateX(15vw);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .main-nav {
      .nav-list {
        // transform: translateX(20vw);
      }
    }
  }
}

.headroom {
  @include media-breakpoint-down(sm) {
    position: fixed !important;
    transform: translate3d(0px, 0px, 0px) !important;
    .foam-finger {
      //transform: scale(0.5) rotate(18deg) !important;
    }
  }
}
