.noise-section {
    position: relative;
    padding: $section-padding;
    background-image: url($images-path + '/community-noise-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center;

    h1{
        font-size: 6.625rem;
        @include media-breakpoint-down(md){
            font-size: 4.625rem;
        }
    }

    p {
        font-size: 1.575rem;
        @include media-breakpoint-down(md){
            font-size: 1.375rem;
            padding: 20px;
        }
    }

    @include media-breakpoint-down(md){
        padding: 80px 0;

        h2{
            font-size: 4.625rem;
        }
    }

    .noise-title {
        font-family: $font-druktext-medium-italic;
        text-transform: uppercase;
        font-size: 4rem;
        margin: 1vw;

        @include media-breakpoint-down(md) {
            font-size: 2rem;
        }
    }

    .container {
        @include media-breakpoint-up(md) {
            max-width: 800px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 980px;
        }
    }

    .row {
        height: 100%;
        flex-direction: column;
    }

    .section-image {
        transform: translateY(122px);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        padding: 5vw;

        .row {
            flex-direction: column;
        }

        .section-image {
            transform: translateY(198px);
        }
    }

    @include media-breakpoint-up(xl) {
        .section-image {
            transform: translateY(170px);
        }
    }

}