.top-bar {
  .callout-text {
    font-size: 1.5rem;
    color: $deep-blue;
    font-family: $font-druktext-medium-italic;
    margin: 0;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      margin-left: 25px;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
  }
}

.hero-title {
  font-family: $font-bison;
  font-style: italic;
  font-weight: 700;
  font-size: 2.4vw;
  padding: 0 20px;
  margin: 0.47em 0;
  @include media-breakpoint-down(lg) {
    font-size: 3vw;
    padding: 0;
  }
  @include media-breakpoint-down(md) {
    font-size: 4vw;
    padding: 0;
  }
}

.videoCTA-title {
  background-image: url($images-path+'/videoCTA.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-family: $font-bison;
  font-weight: 700;
  font-size: 2vw;
  line-height: 4.9vw;
  margin: 0.47em 0;
  color: #111116;
  @include media-breakpoint-up(lg) {
    font-size: 2vw;
    line-height: 4.9vw;
    padding: 0;
  }
}

.partners-heading {
  font-family: $font-bison;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  white-space: nowrap;

  @include media-breakpoint-up(lg) {
    font-size: 1.875rem;
  }
}

.section-title {
  font-family: $font-druk-super-italic;
  //letter-spacing: -.1rem;
  font-size: 6.8rem;
  line-height: 1;
  font-weight: 500;
  color: $white;
  text-transform: uppercase;

  &.green {
    color: $green;
  }

  &.black {
    color: $black;
  }

  &.community-headline {
    font-size: 4.625rem !important;
  }

  &.deep-blue {
    color: $deep-blue;
  }

  @include media-breakpoint-up(lg) {
    font-size: 9.625rem;
    line-height: 0.8;
  }
  @include media-breakpoint-down(md) {
    line-height: 0.8;
    margin-top: 1vw;
    font-size: 5.8rem;
  }
}

.section-text {
  font-family: $font-avenir-regular;
  font-size: 1.375rem;
}

.attention {
  margin: 0;
  font-family: $font-integral;
  font-weight: 700;
  color: $white;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.4rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }

  @media (min-width: 1400px) {
    font-size: 2.25rem;
  }
}

.splitter-cta {
  font-family: $font-integral;
  font-size: 0.9rem;
  color: $white;
  margin: 0;

  > span {
    margin-left: 10px;
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    color: $white;

    > span {
      transform: translateX(10px);
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.2rem;
  }

  @media (min-width: 1400px) {
    font-size: 1.5rem;
  }
}

.button-link {
  font-family: $font-bison;
  font-weight: 500;
  font-style: italic;
  font-size: 2rem;
}

.step {
  font-family: $font-avenir-regular;
  font-size: 1.375rem;
  color: $white;
}

.how-to-item-title {
  font-family: $font-druktext-medium-italic;
  font-size: 2.2rem;
  font-weight: 500;
  color: $green;
  line-height: 1;
  padding-top: 25px;

  @include media-breakpoint-up(lg) {
  }
}

.how-to-item-description {
  font-family: $font-avenir-regular;
  font-size: 1.4rem;
  color: $white;
  padding: 0px 22px;
  min-height: 130px;

  @include media-breakpoint-up(lg) {
  }
}

// All Things Green Section
.blog-title,
.team-title,
.investors-title {
  font-family: $font-druktext-medium-italic;
  font-weight: 500;
  color: $black;
  font-size: 1.8rem;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: 2.625rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1.4rem;
  }
}

.more-blog {
  font-family: $font-avenir-bold;
  font-size: 0.8rem;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

.blog-item-title {
  font-family: $font-avenir-bold;
  font-size: 1.1rem;
  line-height: 1;

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

.blog-item-meta {
  .author,
  .info {
    text-transform: uppercase;
    font-size: 1rem;
  }

  .author {
    font-family: $font-avenir-bold;
  }

  .info {
    font-family: $font-avenir-regular;
  }
}

.founder-name {
  font-family: $font-integral;
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1;
  color: $green;
}

.founder-role {
  font-family: $font-bison;
  font-weight: 500;
  font-size: 1.625rem;
  font-style: italic;
  text-transform: uppercase;
  line-height: 1;
}

.founder-former-role,
.founder-teams-sub {
  font-family: $font-avenir-bold;
  color: $black;
  font-size: 0.9rem;
}

.investor-name {
  font-family: $font-avenir-bold;
  text-transform: uppercase;
  color: $deep-blue;
  font-size: 1.25rem;
}

.investor-leadout {
  font-family: $font-avenir-heavy;
  text-transform: uppercase;
  font-style: italic;
  color: $deep-blue;
  font-size: 1.375rem;
}

.datapartner-title {
  font-family: $font-avenir-bold;
  font-size: 1.25rem;
  color: $deep-blue;
  text-transform: uppercase;
  line-height: 1.6;
}

.footer-list-title {
  font-family: $font-bison;
  font-weight: 500;
  color: $green;
  font-size: 1.75rem;
}

.footer-list-item {
  font-family: $font-avenir-regular;
  color: $white;
  font-size: 1.125rem;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $green;
  }
}

.logo-taunt {
  font-family: $font-bison;
  font-weight: 500;
  font-size: 2rem;
  color: $white;
  font-style: italic;
}

.copyright {
  font-family: $font-avenir-regular;
  font-size: 1.125rem;
  color: $light-gray;
  opacity: 0.4;
}

.nav-item {
  font-family: $font-druktext-medium-italic;
  font-weight: 500;
  color: $deep-blue;
  font-size: 1.5rem;
}

.signup-hero-text {
  font-family: $font-bison;
  font-style: italic;
  font-weight: 700;
  color: $green;
  font-size: 3rem;
  line-height: 1;
  > span {
    display: block;
    font-family: $font-integral;
    font-weight: 500;
    font-style: italic;
    color: $white;
    font-size: 5rem;
    position: relative;
    top: -10px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 5rem;
    > span {
      font-size: 5.375rem;
      line-height: 0.7;
      top: -5px;
    }
  }
}

.signup-hero-subtext {
  font-family: $font-avenir-regular;
  color: $white;
  font-size: 1.25rem;
}

.signup-callout {
  > span {
    font-style: normal;
  }
  font-family: $font-bison;
  font-weight: 500;
  font-style: italic;
  color: $red;
  font-size: 2rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
}
