.gearitems-section {
    position: relative;
    background-image: url($images-path + '/gearitems-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    @include media-breakpoint-down(md) {
    padding-top: 120px;
        .section-image{
            transform: translateY(5%);
        }
        .row {
            flex-direction:column-reverse
        }
    }

    .conatiner,
    .row {
        height: 100%;
    }

    .section-text {
        color: $white;
    }


    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;

        .section-image {
            transform: translateX(-10%) scale(1.2);
        }
    }

}
