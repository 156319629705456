.title-text-section {

    .section-text,
    .button-link {
        margin-top: 25px;
    }

    @include media-breakpoint-up(md) {
        .section-text,
        .button-link {
            margin-top: 50px;
        }
    }
}
