.all-things-green-section {
    background-color: $light-gray;
    // background-image: url($images-path + '/all-things-green-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: $section-padding;

    .section-title {
        margin-bottom: 60px;
    }

    @include media-breakpoint-up(lg) {
        .section-title {
            margin-bottom: 120px;
        }
    }
}
