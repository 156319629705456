.blog-section {
    margin-bottom: 60px;

    .blog-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .section-title {
            margin: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 120px;
    }
}

// BLOG ITEM
.blog-item {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 20px;

    .featured-image {
        img {
            width: 100%;
        }
    }

    .content-wrapper {
        position: relative;
        background-color: white;
        padding: 25px 30px 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 225px;

        &::before {
            content: '';
            position: absolute;
            background-color: $white;
            left: 0;
            right: 0;
            bottom: calc(100% - 1px);
            height: 28px;
            clip-path: polygon(100% 0, 0 100%, 100% 100%);
        }
    }


    .blog-description-bottom {
        display: flex;
        align-items: center;
        ;
    }

    .avatar {
        margin-right: 25px;
        border-radius: 50%;
        overflow: hidden;
        max-width: 60px;
    }

    .blog-item-meta {}

    .author {
        margin: 0;
    }

    .info {
        margin: 0;

        .date {}

        .read-time {}
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}
