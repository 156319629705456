.investors-section {

    .investors-title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    .investors-inner {
        background-color: $light-blue;
        border-radius: 3px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .investor-divider {
        border-top: 2px solid $light-gray;
        width: 100%;
        margin: 75px auto;
    }

    @include media-breakpoint-up(lg) {
        .section-title {
            margin-bottom: 120px;
        }
        .investor-divider {
            width: 70%;
        }
    }
}

.investor-groups {
    text-align: center;

    img {
        padding: 20px
    }
}

.investor-individuals {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 50px;

    .investor-name {
        display: inline-block;
        padding: 10px;
    }
}

.investor-leadout {
    text-align: center;
}
