.fans-section {
    position: relative;
    background-image: url($images-path + '/fans-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .section-text {
        color:white;
    }
    .conatiner,
    .row {
        height: 100%;
    }



    .section-splitter {
        @include center-content(horizontal);
        width: 100%;
    }

    .splitter-inner {
        padding: 10px;
        position: relative;
        background-color: $red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .attention {
            padding-bottom: 8px;
        }
        .splitter-cta {
            padding-bottom: 6px;
        }
    }

    .section-content {
        padding-top: 120px;
        // padding-bottom: 80px;
    }

    @include media-breakpoint-up(xs) {
        .img-fluid {
            max-width: 100%;
        }
        .fanstrademark{
            position: relative;
            top: -.5rem;
            left: .5rem;
            font-size: 5rem;
        }
    }

    @include media-breakpoint-up(md) {

        .section-title{
            font-size: 6.225rem;
        }


        .fanstrademark{
            position: relative;
            top: -0.9rem;
            left: .5rem;
            font-size: 5rem;
        }
    }


    @include media-breakpoint-up(lg) {
        .img-fluid {
            max-width: 150%;
        }

    }

    @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .section-content {
            padding-top: 40px;
            // padding-bottom: 40px;
        }

        .section-image-wrapper {
           // transform: translateY(60px);
        }

        .section-splitter {
            top: -18px;
            width: 80vw;
            max-width: 1200px;
        }
        .splitter-inner {
            padding: 0;
            height: 64px;
            flex-direction: row;
            justify-content: space-evenly;
            margin: 0 72px;

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-top: 32px solid transparent;
                border-bottom: 32px solid transparent;
            }

            &::before {
                left: 1px;
                transform: translateX(-100%);
                border-right: 46px solid $red;
            }

            &::after {
                right: 1px;
                transform: translateX(100%);
                border-left: 46px solid $red;
            }
        }    
    }

    // @include media-breakpoint-up(xl) {
    //     .section-content {
    //         padding-top: 0;
    //         padding-bottom: 0;
    //     }
    // }
}
