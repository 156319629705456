@import 'bootstrap';
$images-path: '../assets/images';
$top-z: 10;

// COLORS --------------------
$black: #000;
$almost-black: #121117;
$light-gray: #ececf2;
$white: #fff;
$red: #ff4654;
$green: #00e6ae;
$light-blue: #faf9ff;
$gray-blue: #e4e4ef;
$blue: #1c6cf4;
$deep-blue: #001e37;
$background-color: $white;

// Variables
$top-bar-height: 70px;
$hero-height: calc(95vw - #{$top-bar-height});
$section-padding: 120px 0;

// Hamburger Variables
$hamburger-layer-color : $deep-blue !default;
// $hamburger-types : (elastic);

@import 'node_modules/hamburgers/_sass/hamburgers/hamburgers';
