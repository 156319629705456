@mixin center-content($orientation:both) {
    // center content with an unknown width or height (or both)
    // param: $orientation (scss param) the orientation you wish to set
    //
    // note:
    //     - $orientation accepts:
    //         - both (default)
    //         - horizontal (centers width only)
    //         - vertical (centers height only)
    //         - reset (resets)
    //
    // see:
    //     - http://css-tricks.com/centering-percentage-widthheight-elements/


    @if $orientation==both {
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
    }

    @if $orientation==horizontal {
        transform: translate(-50%, 0);
        position: absolute;
        left: 50%;
    }

    @if $orientation==vertical {
        transform: translate(0, -50%);
        position: absolute;
        top: 50%;
    }

    @if $orientation==reset {
        -webkit-transform: none;
        /* Safari and Chrome */
        -moz-transform: none;
        /* Firefox */
        -ms-transform: none;
        /* IE 9 */
        -o-transform: none;
        /* Opera */
        transform: none;
        position: static;
        top: auto;
        left: auto;
    }
}
