.how-to-greenpark-section {
    position: relative;
    padding: $section-padding;
    background-image: url($images-path + '/how-to-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        .how-to-greenpark-section{
            padding: 0px;
        }
    }

    .how-to-title {
        @extend .section-title;
        text-align: center;
        padding: 0 15px;
        margin-bottom: 120px;
        color: $green;
        text-transform: uppercase;
    }

    .greenpark-step-list {
        padding: 0 0px;
        max-width: 1400px;
        margin: 0 auto;
        // display: grid;
        // column-gap: 10px;
        // row-gap: 1em;
        // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.how-to-item-wrapper {
    flex: 0 0 calc(20% - 10px);
    min-width: 370px;
    margin: 5px 15px 10px 15px;

    .how-to-item {
        text-align: center;
        position: relative;
    }

    .step {
        position: absolute;
        top: -12px;
        left: 20px;
        height: 40px;
        width: 40px;

        span {
            position: relative;
            // z-index: 2;
            top: 3px;
            left: 1px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $green;
            // z-index: 1;
            transform: rotate(48deg) skew(10deg);

        }
    }

    .how-to-image {
        border: 1px solid #442195;
        //margin-bottom: 36px;
    }

    .content-wrapper {
        padding: 0px 10px;
        background-color: #442195;
    }
}
