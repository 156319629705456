.partner-section {
    position: relative;
    padding: $section-padding;
    background-image: url($images-path + '/partner-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    .conatiner,
    .row {
        height: 100%;
    }

    .section-text {
        color: $white;
    }

    .button-link {
        background-color: $white;
        color: $blue;
    }

    .section-image {
        transform: translateY(122px);
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;

        .section-image {
            transform: translateY(188px);
        }
    }

    @include media-breakpoint-up(xl) {
        .section-image {
            transform: translateY(145px);
        }
    }
}
