.subpage {
    background-color: $deep-blue;
    text-align: left;
    padding: 50px 0;

    .logo {
        text-align: center;
    }
}

.subpage-headline {
    @extend .section-title;
    color: $green;
    margin-bottom: 50px;
    text-align: center;
}

.subpage-main-content {
    table {
        align-self: flex-start;
        width: 100%;
    }

    a {
        color: $blue;
    }
}

.subpage-main-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    padding: 80px 20px;
    overflow: auto;
    margin: 0 auto 88px;
    text-align: left;
}

.subpage-main-content h1 {
    font-family: $font-avenir-bold;
    font-weight: 500;
    font-size: 30px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 40px;
    color: #171d3c;
    display: block;
    width: 100%;
    text-align: center;
}

.subpage-main-content h2 {
    font-family: $font-avenir-regular;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 26px;
    text-transform: uppercase;
    color: #171d3c;
    margin-bottom: 15px;
    display: block;
    width: 100%;
    text-align: center;
}

.subpage-main-content span {
    font-family: $font-avenir-regular;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.03em;
    color: black;
}

.subpage-main-content strong {
    font-family: $font-bison;
    font-size: calc(1rem + 15%);
    line-height: 26px;
    // letter-spacing: -0.03em;
    color: black;
}
