.signs-section {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: url($images-path + '/community-signs-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    p{
        font-family: "AvenirNextLTW01-Regular";
        font-size: 1.375rem;
        color: $white;
    }

    a {
        color: $red;
    }

    @include media-breakpoint-down(md) {
            padding-top: 80px;
    }

    .conatiner,
    .row {
        height: 100%;
    }

    .section-text {
        color: $white;
    }

    .button-link {
        background-color: $white;
        color: $blue;
    }



    @include media-breakpoint-down(md) {
        .section-text{
            margin-top: 25px;
        }
    }

    @include media-breakpoint-down(lg) {
        display: flex;
        align-items: center;

        .section-image {
            transform: translate(1vw, 20px) scale(1.2);
        }
    }

    @include media-breakpoint-up(xl) {
        .section-image {
            transform: translate(3vw, 10px) scale(1.2);
        }
    }
}
