.datapartner-section {
    background-color: $gray-blue;
    padding: 80px 0 100px;

    .datapartner-title {
        margin: 0;
        text-align: center;
    }

    .sport-trader-logo {
        margin-bottom: 30px;
    }

    .col {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .datapartner-title {
        margin-bottom: 30px;
    }

    @include media-breakpoint-up(md) {

        .datapartner-title {
            text-align: left;
        }

    }

    @include media-breakpoint-up(lg) {
        padding: 100px 0;

        .col {
            flex-direction: row;
            justify-content: space-around;
        }

        .datapartner-title,
        .sport-trader-logo {
            margin-bottom: 0;
        }
    }
}
